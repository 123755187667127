<template>
  <div class="container">
    <myheader></myheader>
    <!-- 轮播图 -->
    <div class="rotation-chat">
      <a-carousel autoplay>
        <a target="_blank" :href="banner.linkAddress" v-for="(banner,index) in banners" :key="index"><img alt="" :src="cdn+banner.cover" /></a>
        <div v-if="banners.length == 0"><img alt="" src="@/assets/rotation1.png" /></div>
        <div v-if="banners.length == 0"><img alt="" src="@/assets/rotation2.png" /></div>
        <div v-if="banners.length == 0"><img alt="" src="@/assets/rotation3.png" /></div>
      </a-carousel>
    </div>

    <div class="body" hidden>
      <!-- 新闻与活动 -->
      <div class="new-activity wow animate__fadeInUp">新闻与活动</div>
      <!-- 蓝条 -->
      <div class="blue-bar"></div>
      <!-- 新闻资讯,活动通知 -->
      <div class="news-activity wow animate__fadeInUp">
        <button class="news-information" :class="{ 'new-active': typeIdx == 1 }" @click="go" data-id="1" @mouseover="typeIdx = 1">新闻资讯</button>
        <button class="activity-notification" :class="{ 'new-active': typeIdx == 2 }" @click="go" data-id="2" @mouseover="typeIdx = 2">活动通知</button>
      </div>
      <!-- 新闻与活动 -->
      <div class="content">
        <div class="text-box wow animate__fadeInUp" @click="skipOneNews(text.id)" v-for="(text, index) in typeIdx == 1 ? section1001Data : section1003Data" :key="'a' + index">
          <div class="xiao-box">
            <div>
              <img style="width:200px; height:200px" alt="" :src="cdn + text.cover" />
            </div>
            <div class="text-name1">{{ text.title }}</div>
            <div class="text-name"><span v-html="ellipsis(text.content)"></span></div>
            <div class="text-type">{{ text.publishDate }}</div>
          </div>
        </div>
      </div>
      <div><div class="new_button wow animate__fadeInUp" @click="$router.push('news_information')">更多资讯</div></div>
    </div>

    <div style="background-color: #f8f8f8; overflow: hidden; margin-top: 30px; padding: 25px 0 65px 0">
      <div class="body1 wow animate__fadeInUp">
        <!-- 平台与资源 -->
        <div class="platform-resources">平台与资源</div>
        <!-- 蓝条 -->
        <div class="blue-bar1"></div>
        <!-- 多年经验沉淀,汇聚行业、企业、院校专家与一线教师协力打造 -->
        <div class="body1-word">公司自主研发了教学实训平台、技能竞赛平台和云资源管理平台，开发项目化课程及相关模块化教学资源，为高职专科、职教本科、应用型本科在新工科、新一代信息技术专业群领域提供“岗课赛证”综合育人一体化解决方案。</div>
        <!-- 平台与资讯图 -->
        <div class="platform-body">
          <div class="good-box1" v-for="(good, index) in goods" :key="'a' + index">
            <!-- platform图1 -->
            <div class="box1">
              <div><img alt="" style="height: 87px; margin-top: 30px; margin-left: 15px" :src="good.url" /></div>
              <div class="platform-word1">{{ good.word1 }}</div>
              <div class="platform-word2">{{ good.word2 }}</div>
              <div>
                <button
                  onmouseover="this.style.backgroundColor = '#2e71fe';this.style.color = 'white';"
                  onmouseout="this.style.backgroundColor = 'white';this.style.color = '#2e71fe'"
                  class="platform-word3"
                  @click="skipLink(good.link)"
                >
                  {{ good.word3 }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="body2">
      <!-- 整体解决方案 -->
      <div class="solution wow animate__fadeInUp">整体解决方案</div>
      <!-- 蓝条 -->
      <div class="blue-bar2 wow animate__fadeInUp"></div>
      <!-- 助力职业院校新一代信息技术专业群建设,高级能人才培养 -->
      <div class="body2-word2 wow animate__fadeInUp">助力职业院校新一代信息技术专业群建设,高级技能人才培养</div>
      <!-- 导航栏 -->
      <div class="contaion">
        <div class="navigation_head wow animate__fadeInLeft">
          <div class="nav-box1" :class="ter[index]" v-for="(nav, index) in navs" :key="'a' + index">
            <div style="display: flex">
              <div class="box2" :class="{' box2-selected':curNav.id == nav.id}" >
                <div @mouseenter="show_nav(nav)" class="navigation-title1">{{ nav.title }}</div>
              </div>
              <!-- 三角 -->
              <div class="triangle"></div>
            </div>
          </div>
        </div>
        <div class="navigation-right wow animate__fadeInRight">
          <!-- 中国特色企业新型学徒制     -->
          <div class="navigation-title2">{{curNav.title}}</div>
          <!-- 虚线 -->
          <div class="dotted-line"></div>
          <!-- title3 -->
          <div class="navigation-title3">
            {{curNav.intro}}
          </div>
          <div style="display: flex; margin-top: 44px;padding-left:50px">
            <div v-for="(tag,index) in curNav.tags" :key="index" class="title-box1">{{tag}}</div>
          </div>
          <div class="liaojie" @click="skipCWLink()" hidden>了解更多</div>
        </div>
      </div>
    </div>

    <!-- 背景图 -->
    <div class="background-image">
      <div class="body3">
        <!-- 整体解决方案 -->
        <div class="case wow animate__fadeInUp">服务全国380+院校客户</div>
        <!-- 蓝条 -->
        <div class="blue-bar3 wow animate__fadeInUp"></div>
        <!-- 助力职业院校新一代信息技术专业群建设,高级能人才培养 -->
        <div class="body3-word3 wow animate__fadeInUp">及时响应客户诉求 用心保障服务质量</div>
        <!-- 院校案例 -->
        <div class="logo-body">
          <div class="logo-box1" v-for="(logo, index) in logos" :key="'a' + index">
            <!-- logo图1 -->
            <div class="box3 wow animate__fadeInUp">
              <div class="box3-picture">
                <img alt="" style="width: 40px; height: 40px" :src="logo.url" />
              </div>
              <div style="width: 115px; height: 70px">
                <div class="logo-zi1">{{ logo.zi1 }}</div>
                <div class="logo-zi2">{{ logo.zi2 }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- logo墙 -->
        <div class="logo-qiang wow animate__fadeInUp"><img alt="" src="@/assets/background_logo.png" /></div>
      </div>
    </div>
    <mybottom></mybottom>
  </div>
</template>

<script>
import myheader from '@/components/Header.vue';
import mybottom from '@/components/Bottom.vue';

export default {
  components: { myheader, mybottom },
  data() {
    return {
      section1001Data: [],
      section1003Data: [],
      banners: [],
      texts: [
        {
          url: require('@/assets/news_events1.png'),
          types: '人社部印发通知加强新职业培训工作通知',
          type01: '为加快培养大批高素质劳动者和技术技能人才，改善新职业人才供给质量结构，支持战略性新...',
          type02: '2021-08-23',
        },
        {
          url: require('@/assets/news_events2.png'),
          types: '人社部印发通知加强新职业培训工作通知',
          type01: '为加快培养大批高素质劳动者和技术技能人才，改善新职业人才供给质量结构，支持战略性新...',
          type02: '2021-08-23',
        },
        {
          url: require('@/assets/news_events3.png'),
          types: '人社部印发通知加强新职业培训工作通知',
          type01: '为加快培养大批高素质劳动者和技术技能人才，改善新职业人才供给质量结构，支持战略性新...',
          type02: '2021-08-23',
        },
      ],
      goods: [
        {
          url: require('@/assets/competition_platform.png'),
          word1: '实训平台',
          word2: '云计算.大数据.人工智能教学实训评测竞赛一体化平台',
          word3: '了解详情',
          backgroundColor: 'blue',
          link: 'training_platform',
        },
        {
          url: require('@/assets/training_platform.png'),
          word1: '竞赛平台',
          word2: '对接全国职业院校技能大赛"云计算"赛项规程,支持混合云竞赛模式',
          word3: '了解详情',
          link: '',
        },
        {
          url: require('@/assets/cloud_platform.png'),
          word1: '云管平台',
          word2: '提供公有云、私有云、混合云一站式多云管理和调配',
          word3: '了解详情',
          link: 'couldManage',
        },
        {
          url: require('@/assets/course.png'),
          word1: '课程资源',
          word2: '七大专业方向课程体系220门模块课程课件、视频、教材、实验、评测',
          word3: '了解详情',
          link: '',
        },
      ],
      navs: [
        {
          id:1,
          title: '专业实训室',
          intro: '公司专注于职教云计算、大数据、人工智能、软件开发、虚拟现实等计算机类专业实训室产品研发，提供岗课赛证综合育人整体方案。',
          tags:["云计算实训室建设方案","大数据实训室建设方案","人工智能实训室建设方案","虚拟仿真实训室建设方案"]
        },
        {
          id:2,
          title: '工匠工坊',
          intro: '基于现代学徒制，项目任务驱动制，以培养具有工匠精神的高水平技术技能人才，推进“双师型”教师队伍建设，打造对外服务平台为目标，校企共建工匠工坊，提升职业教育育人质量，促进职业教育与产业协同发展。',
          tags:["人才培养模型","育人模式"]
        },
        {
          id:3,
          title: '培训认证',
          intro:"公司开发的云计算平台、实训竞赛平台软件，已经与华为鲲鹏云完成兼容性认证，公司参与教育部、人社部相关培训，是人社部2020年发文推荐的54家线上学习平台之一，提供1+X Web前端开发、1+X云计算平台运维与开发等职业技能等级证书培训，免费开放技术平台在线培训帐号、在线训练环境、在线模拟考试场次供院校学生使用；协助高职院校建设“1+X”证书培训中心和认证中心。",
          tags:["云计算职业技能培训","l+X 培训","就业服务"]
        },
        {
          id:4,
          title: '资源开发',
          intro:"公司专注于云计算、大数据、 1+X云计算运维与开发、1+X Web前端开发的培训资源和教材开发，将现有资源整合，重新按职业规划的角度开发模块化课程，力求打造“做中教、做中学”的理实—体化课堂。",
          tags:["4大对接","24本专业教材出版","25门云计算和l+X证书配套课程","220门模块化课程"]
        }
      ],
      curNav:{},
      ter: ['s1', 's2'],
      logos: [
        {
          url: require('@/assets/cooperative.png'),
          zi1: '380+',
          zi2: '合作院校',
        },
        {
          url: require('@/assets/modular.png'),
          zi1: '220+',
          zi2: '模块化课程',
        },
        {
          url: require('@/assets/textbook.png'),
          zi1: '20+',
          zi2: '教材开发',
        },
        {
          url: require('@/assets/training.png'),
          zi1: '1800+',
          zi2: '师资培训',
        },
      ],
      typeIdx: 1,
    };
  },
  mounted() {
    var wow = new this.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    });
    wow.init();
  },
  created() {
    this.listNews();
    this.loadBanners();
    this.curNav = this.navs[0];
  },
  methods: {
    /**查看新闻 */
    skipOneNews(id) {
      //跳转至查看界面
      this.$router.push({
        name: 'loadOneNews',
        query: {
          id: id, //携带新闻编号
        },
      });
    },
    async loadBanners() {
      let reqData = {};
      let reqParam = { name: '首页' };
      let urlParam = `?name=${reqParam.name}&`;
      let loadFriendLinksUrl = `/home/loadBanner${urlParam}`;
      let result = await this.axios.get(loadFriendLinksUrl, reqData);
      this.banners = result.data.data
    },
    skipCWLink() {
      this.$router.push('craftsman_workshop');
    },
    skipLink(link) {
      this.$router.push(link);
    },
    go(e) {
      let flag = e.target.dataset.id;
      this.tab = flag;
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    ellipsis(value) {
      if (!value) return '';
      value = this.delHtmlTag(value);
      if (value.length > 70) {
        return value.slice(0, 70) + '...';
      }
      return value;
    },
    delHtmlTag(str) {
      return str.replace(/<[^>]+>/g, ''); //正则去掉所有的html标记
    },
    async listNews() {
      let reqParam = { page: '1', pageSize: '3', sectionId: 1001 };
      let urlParam = `?sectionId=${reqParam.sectionId}&`;
      let loadNewsListUrl = `/home/listNews/${reqParam.page}/${reqParam.pageSize}${urlParam}`;
      let result = await this.axios.get(loadNewsListUrl);
      this.section1001Data = result.data.data;

      reqParam = { page: '1', pageSize: '3', sectionId: 1003 };
      urlParam = `?sectionId=${reqParam.sectionId}&`;
      loadNewsListUrl = `/home/listNews/${reqParam.page}/${reqParam.pageSize}${urlParam}`;
      result = await this.axios.get(loadNewsListUrl);
      this.section1003Data = result.data.data;
    },
    show_nav(nav){
      this.curNav = nav;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.rotation-chat {
  min-width: 1200px;
  max-width: 1920px;
  width: 100%;
  //   width: 100%;
  // height: 420px;
  margin: auto;
  justify-content: center;
  img {
    width: 100%;
  }
}
.body {
  width: 1200px;
  margin: auto;
  justify-content: center;
}
.new-activity {
  margin-top: 35px;
  width: 100%;
  height: 34px;
  text-align: center;
  font-size: 34px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  letter-spacing: 1px;
}
.blue-bar {
  width: 45px;
  height: 3px;
  background-color: #2e71fe;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.news-activity {
  display: flex;
  width: 320px;
  height: 45px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
}
.new-active {
  width: 160px;
  height: 45px;
  background-color: #2e71fe !important;
  color: rgba(0, 0, 0, 0.65);
  letter-spacing: 1px;
  color: white;
  border: #2e71fe;
}
.news-information {
  width: 160px;
  height: 45px;
  background-color: #f5f5f5;
  border: white;
  cursor: pointer;
}
.news-information:hover {
  width: 160px;
  height: 45px;
  background-color: #2e71fe;
  color: rgba(0, 0, 0, 0.65);
  letter-spacing: 1px;
  color: white;
  border: #2e71fe;
}
.activity-notification {
  width: 160px;
  height: 45px;
  background-color: #f5f5f5;
  border: white;
  cursor: pointer;
}
.activity-notification:hover {
  width: 160px;
  height: 45px;
  background-color: #2e71fe;
  color: rgba(0, 0, 0, 0.65);
  letter-spacing: 1px;
  color: white;
  border: #2e71fe;
}
.content {
  display: flex;
  width: 100%;
}
.text-box {
  justify-content: space-between;
  padding: 20px;
}
.xiao-box {
  margin-top: 20px;
  width: 360px;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.xiao-box:hover {
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2);
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
}
.xiao-box:hover .text-name1 {
  color: #2e71fe;
}
.text-name1 {
  width: 300px;
  height: 40px;
  color: black;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 600;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.text-name {
  margin-top: 20px;
  width: 300px;
  height: 40px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.text-type {
  margin-left: 30px;
  margin-top: 16px;
}
.new_button {
  width: 150px;
  height: 40px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  color: #2e71fe;
  border: 1px solid#2e71fe;
  background-color: white;
  cursor: pointer;
  line-height: 38px;
  text-align: center;
}
.body1 {
  width: 1200px;
  margin: auto;
  justify-content: center;
  margin-top: 30px;
}
.platform-resources {
  // margin-top: 70px;
  width: 100%;
  text-align: center;
  height: 34px;
  font-size: 30px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  letter-spacing: 1px;
}
.blue-bar1 {
  width: 45px;
  height: 3px;
  background-color: #2e71fe;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.body1-word {
  margin-top: 20px;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}
.platform-body {
  width: 1200px;
  height: 380px;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}
.box1 {
  padding-top: 8px;
  width: 280px;
  height: 380px;
  text-align: center;
  background-color: #ffffff;
  // margin-bottom:30px
  cursor: pointer;
}
.box1:hover {
  box-shadow: 2px -2px 10px rgba(0, 0, 0, 0.2);
  margin-top: -8px;
}
.platform-word1 {
  font-size: 23px;
  font-weight: 600;
  margin-top: 35px;
}
.platform-word2 {
  // width: 170px;
  padding: 0 25px;
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 30px;
  text-indent: 20px;
  text-align: center;
  // margin-left: 50px;
}
.platform-word3 {
  width: 130px;
  height: 35px;
  margin-top: 45px;
  border: 1px solid #2e71fe;
  color: #2e71fe;
  background-color: #ffffff;
  cursor: pointer;
}
.body2 {
  width: 1200px;
  margin: auto;
  justify-content: center;
  margin-top: 50px;
}
.solution {
  margin-top: 70px;
  width: 100%;
  text-align: center;
  height: 34px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
}
.blue-bar2 {
  width: 45px;
  height: 3px;
  background-color: #2e71fe;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.body2-word2 {
  margin-top: 20px;
  width: 460px;
  font-size: 17px;
  margin-left: auto;
  margin-right: auto;
}
.contaion {
  margin-top: 80px;
  width: 100%;
  display: flex;
}
.navigation_head {
  width: 260px;
  height: 400px;
  background-color: #f9f9f9;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}
.navigation-title1 {
  width: 260px;
  height: 66px;
  font-size: 17px;
  text-indent: 40px;
  line-height: 66px;
}
.box2-selected + .triangle {
  width: 20px;
  height: 30px;
  border: 15px solid transparent;
  border-left: 15px solid #2e71fe;
  margin-top: 17px;
}
.box2 {
  width: 300px;
}
.box2-selected {
  background-color: #2e71fe;
  color: #f9f9f9;
}
// .box2:hover {
//   background-color: #2e71fe;
//   color: #f9f9f9;
// }
.navigation-title2 {
  color: #2e71fe;
  margin-top: 8px;
  margin-left: 70px;
  font-size: 20px;
  font-weight: 600;
}
.navigation-right {
  width: 100%;
}
.dotted-line {
  width: 870px;
  height: 1px;
  border: 1px solid #ced0cf;
  margin-left: 70px;
  margin-top: 32px;
  background-color: #ced0cf;
}
.navigation-title3 {
  margin-left: 70px;
  margin-top: 32px;
  font-size: 16px;
  font-weight: 600;
}
.title-box {
  width: 148px;
  height: 38px;
  margin-left: 70px;
  background-color: #f9f9f9;
  line-height: 38px;
  text-align: center;
}
.title-box1 {
  min-width: 150px;
  height: 38px;
  padding: 0 10px;
  margin-left: 70px;
  background-color: #f9f9f9;
  margin-left: 18px;
  line-height: 38px;
  text-align: center;
}
.liaojie {
  width: 150px;
  height: 40px;
  border: 1px solid #2e71fe;
  color: #2e71fe;
  background-color: white;
  text-align: center;
  line-height: 40px;
  margin-left: 70px;
  margin-top: 70px;
}
.background-image {
  width: 100%;
  min-width: 1200px;
  height: 750px;
  margin-top: 50px;
  background-image: url('../../assets/background.png');
}
.body3 {
  width: 1200px;
  margin: auto;
  padding: 70px 0 0 0;
  justify-content: center;
}
.case {
  height: 34px;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
}
.blue-bar3 {
  width: 45px;
  height: 3px;
  background-color: #2e71fe;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.body3-word3 {
  height: 34px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  text-align: center;
}
.logo-body {
  width: 1200px;
  display: flex;
  justify-content: space-between;
}
.box3 {
  width: 250px;
  height: 120px;
  text-align: center;
  // line-height:80px;
  display: flex;
  margin-left: 60px;
  margin-top: 50px;
}
.box3-picture {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  background-color: white;
}
.logo-zi1 {
  width: 115px;
  height: 35px;
  font-size: 35px;
  font-weight: 700;
  color: #2e71fe;
}
.logo-zi2 {
  width: 115px;
  height: 35px;
  font-size: 20px;
  font-weight: 600;
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.65);
}
.logo-qiang {
  width: 1200px;
  margin: auto;
  margin-top: 30px;
}
</style>
